export const LANGUAGES = [
	{
		key: 'JPN',
		name: 'Japanese',
	},
	{
		key: 'ENG',
		name: 'English',
	},
	{
		key: 'FRA',
		name: 'French',
	},
	{
		key: 'ESP',
		name: 'Spanish',
	},
	{
		key: 'RUS',
		name: 'Russian',
	},
	{
		key: 'ARA',
		name: 'Arabic',
	},

]